require('@/lib/jquery.min.js');
require('@/lib/jquery.i18n.properties.js');
require('@/js/common.js');

var ServiceFactory = require('@/service/service.js');

// TODO: Delete the default values of hashCode and redirectUri, and check whether hashCode and redirectUri exist
var hashCode = getQueryString('hashCode') || '';
var redirectUri = getQueryString('redirectUri') || '';
var authClientId = getQueryString('authClientId') || '';
var serviceFactory = new ServiceFactory({
  hashCode: hashCode,
  redirectUri: redirectUri,
  authClientId: authClientId
});
$(function () {
  const PublicKey = GetRandomNum()
  sessionStorage.setItem('PublicKey', PublicKey);
  serviceFactory.getConfigInfo()
  addScrollListener()
  init();
});



/**
 * 监听滚动条滑动
 */
function addScrollListener() {
  $('.agreement-wrapper').bind('scroll',function() {
    const scrollTop = $(this).scrollTop(); // 获取滚动条距离顶部的距离
    const scrollHeight = $(this)[0].scrollHeight; // 获取元素的总高度
    const clientHeight = $(this).innerHeight(); // 获取元素的可视高度
    // 判断滚动条是否到达底部
    if (Math.ceil(scrollTop + clientHeight + 5) >= scrollHeight) {
      // 滚动到底部时执行的代码
      $('#agree').addClass('change-background')
    }
  });
}

function GetRandomNum(n) {
  let chars = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F',
    'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
    'W', 'X', 'Y', 'Z', '.', '?', '~', '!', '@', '#', '$', '%', '^', '&', '*']
  if (n == null) {
    n = 16
  }
  let res = ""
  for (let i = 0; i < n; i++) {
    let id = Math.ceil(Math.random() * 46)
    res += chars[id]
  }
  return res
}

function init() {
  var date = new Date();
  this.year = date.getFullYear();
  var value = this.year;
  $("#copyRightFooter").html(value)
  // preloading
  setTimeout(function () {
    closePreLoading();
  }, 500);
  // enable "I agree" button when load T&C successfully
  // $("#J_app_wrapper .agreement-button.confirm").click(function () {
  //   var nextPage = './account.html?hashCode=' + hashCode + '&redirectUri=' + redirectUri + '&authClientId=' + authClientId;
  //   serviceFactory.handelLogo(nextPage, hashCode)
  //
  // });
  // Disagree
  $("#J_button_group .cancel").click(function () {
    // Priority jump redirectUri
    if (redirectUri) {
      location.href = decodeURIComponent(redirectUri);
    } else {
      // Go to pocket error page
      location.href = 'error.html';
    }
  });
}

/**
 * showPreLoading
 */
function showPreLoading() {
  $("#J_preloader_wrapper").removeClass("fn-hide");
}

/**
 * closePreLoading
 */
function closePreLoading() {
  $("#J_preloader_wrapper").addClass("fn-hide");
}
